<template>
     <div>
          <navbar></navbar>
          <FadeTransition :duration="400" mode="out-in">
               <router-view  style="min-height:100%;"></router-view>
          </FadeTransition>
          <footerComp></footerComp>
     </div>
</template>

<script>
     import { FadeTransition } from "vue2-transitions";
     import navbar from "../components/nav";
     import footerComp from "../components/footer";
     export default {
          data() {
               return {
                    isOpen: false,
               };
          },
          components: {
               FadeTransition,
               footerComp,
               navbar,
          },
     };
</script>

<style></style>
